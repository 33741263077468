import styled from "styled-components";
import { handleFormatDate, handleGetTotalSeconds } from "../../../../helpers";
import { Day } from "./Day";
import { Total } from "./Total";

export const Week = ({
  weekData,
  activeDay,
  onChangeActiveDay,
  timers,
  obsencesData,
}) => {
  const WEEK_DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <StyledWeek className="flex border rounded overflow-hidden">
      <div className="w-full grid divide-x text-sm grid-cols-[repeat(7,1fr)_70px] weekly-calender">
        {weekData.map((date, i) => (
          <Day
            key={i}
            title={WEEK_DAYS[i]}
            active={handleFormatDate(activeDay) === handleFormatDate(date)}
            onClick={() => onChangeActiveDay(date)}
            total={
              timers?.length > 0
                ? handleGetTotalSeconds(
                    timers
                      ?.filter((d) => d.timer_type !== 2)
                      ?.filter(
                        (d) =>
                          handleFormatDate(d?.date_start) ===
                          handleFormatDate(date)
                      )
                  )
                : "0.00"
            }
            holiday={obsencesData?.find(
              (d) =>
                handleFormatDate(new Date(d?.data_weekend)) ===
                handleFormatDate(date)
            )}
          />
        ))}
        <Total
          total={
            timers?.length === 0
              ? "0.00"
              : handleGetTotalSeconds(
                  timers
                    ?.filter((d) => d.timer_type !== 2)
                    ?.filter((d) =>
                      weekData
                        ?.map((date) => handleFormatDate(date))
                        ?.includes(handleFormatDate(d?.date_start))
                    )
                )
          }
        />
      </div>
    </StyledWeek>
  );
};

const StyledWeek = styled.div`
  @media (max-width: 800px) {
    width: calc(100vw - 108px);
    overflow: auto !important;
  }
`;
