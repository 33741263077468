import styled from "styled-components";
import { Header } from "./Header";
import { Calendar } from "./Calendar/Calendar";
import { handleFormatDate, handleGetToday } from "../../helpers";
import { useEffect, useState } from "react";
import { Timers } from "./Timers/Timers";
import { TimerModal } from "../TimerModal/TimerModal";
import {
  deleteTimer,
  endPauseTimer,
  endTimer,
  getCalendar,
  resetTimer,
  startPauseTimer,
  startTimer,
} from "../../api/methods";
import { Confirm } from "../Confirm";
import cogoToast from "cogo-toast";
import { EditTimerModal } from "../EditTimerModal";

export const Tracking = ({ readOnly, className, obsencesData }) => {
  const [activeDay, setActiveDay] = useState(handleGetToday(true));
  const [openTimerModal, setOpenTimerModal] = useState(false);
  const [timers, setTimers] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editTimer, setEditTimer] = useState(false);

  const handleOpenTimerModal = () => setOpenTimerModal(true);

  const handleFormatActiveDate = () => {
    const date = new Date(activeDay);
    const timeInGermany = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin",
      })
    );

    console.log(timeInGermany);
    date.setHours(timeInGermany?.getHours());
    date.setMinutes(timeInGermany?.getMinutes());
    date.setSeconds(timeInGermany?.getSeconds());
    return handleFormatDate(date, true);
  };

  const handleAddTimer = (id) => {
    if (id) {
      resetTimer(id).then((resp) => {
        setTimers(
          timers?.map((t) =>
            id === t.id
              ? {
                  ...resp?.data?.calendar_date,
                }
              : t
          )
        );
      });
    } else {
      startTimer({ dateStart: handleFormatActiveDate() }).then((resp) => {
        setTimers([
          ...timers,
          {
            ...resp?.data?.calendar_date,
          },
        ]);
      });
    }
  };

  const handleUpdateTimerById = (id, timer) =>
    setTimers(timers?.map((t) => (id === t.id ? timer : t)));

  const handleStopTimer = (id) => {
    endTimer({ id, dateEnd: handleFormatActiveDate() }).then((resp) => {
      handleUpdateTimerById(id, resp?.data?.calendar_date);
    });
  };

  const handleGetTimers = () => {
    getCalendar().then((resp) => {
      setTimers(resp?.data?.dates ?? []);
    });
  };

  useEffect(() => {
    !readOnly && handleGetTimers();
  }, []);

  const handlePauseTimer = () => {
    startPauseTimer({
      dateStart: handleFormatActiveDate(),
    }).then((resp) => {
      setTimers([
        ...timers,
        {
          ...resp?.data?.calendar_date,
        },
      ]);
    });
  };

  const handleStopPause = (id) => {
    endPauseTimer({ id, dateEnd: handleFormatActiveDate() }).then((resp) => {
      handleUpdateTimerById(id, resp?.data?.calendar_date);
    });
  };

  const handleDeleteTimer = (id) => {
    deleteTimer(id)
      .then(() => {
        setTimers(timers.filter((t) => t.id !== id));
        cogoToast.success("Success");
      })
      .catch(() => {
        cogoToast.error("Error");
      });
  };

  const handleDeleteConfirm = () => {
    handleDeleteTimer(deleteModal);
    setDeleteModal(null);
  };

  return (
    <StyledTracking className={`className`}>
      {deleteModal && (
        <Confirm
          onClose={() => setDeleteModal(null)}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {openTimerModal && (
        <TimerModal
          onClose={() => setOpenTimerModal(false)}
          activeDay={activeDay}
        />
      )}
      {editTimer && (
        <EditTimerModal
          onClose={() => setEditTimer(false)}
          timer={editTimer}
          onSuccess={handleUpdateTimerById}
        />
      )}
      {readOnly ||
      obsencesData?.find(
        (d) =>
          handleFormatDate(new Date(d?.data_weekend)) ===
          handleFormatDate(activeDay)
      ) ? null : (
        <Header
          activeDay={activeDay}
          onAddTimerModal={handleOpenTimerModal}
          onAddTimer={handleAddTimer}
          isTimer={
            timers
              ?.filter(
                (d) =>
                  handleFormatDate(d?.date_start) ===
                  handleFormatDate(activeDay)
              )
              ?.filter((d) => !d?.date_end)?.length > 0
          }
          onPause={handlePauseTimer}
        />
      )}
      <Calendar
        activeDay={activeDay}
        onChangeActiveDay={(val) => setActiveDay(val)}
        timers={timers}
        readOnly={
          readOnly ||
          obsencesData?.find(
            (d) =>
              handleFormatDate(d?.data_weekend) === handleFormatDate(activeDay)
          )
        }
        obsencesData={obsencesData}
      />
      <Timers
        activeDay={activeDay}
        timers={timers?.filter(
          (d) => handleFormatDate(d?.date_start) === handleFormatDate(activeDay)
        )}
        onStopTimer={handleStopTimer}
        onAddTimer={handleAddTimer}
        readOnly={readOnly}
        onStopPause={handleStopPause}
        isPause={
          timers
            ?.filter(
              (d) =>
                handleFormatDate(d?.date_start) === handleFormatDate(activeDay)
            )
            ?.filter((d) => d.timer_type === 2)
            ?.filter((d) => !d?.date_end)?.length > 0
        }
        onDelete={(id) => setDeleteModal(id)}
        onEdit={(timer) => setEditTimer(timer)}
      />
    </StyledTracking>
  );
};

const StyledTracking = styled.div`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid #e5e7eb;
  background: #fff;
`;
