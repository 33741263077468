import { useState } from "react";
import { handleFormatDate } from "../../helpers";
import styled from "styled-components";
import { printCalendarDate } from "../../api/methods";

export const DownloadModal = ({ onClose, userId, onLoading }) => {
  const dateSevenDaysBefore = new Date();
  dateSevenDaysBefore.setDate(dateSevenDaysBefore.getDate() - 7);

  const [data, setData] = useState({
    date_from: handleFormatDate(dateSevenDaysBefore, false, false, true),
    date_to: handleFormatDate(new Date(), false, false, true),
    clinic: "",
    referenzperson: "",
    departement: "",
  });

  const handleChangeField = (field, value) =>
    setData({ ...data, [field]: value });

  const handleSubmit = () => {
    const { date_from, date_to, clinic, referenzperson, departement } = data;
    onLoading(true);
    const sendData = {
      user_id: userId,
      date_from:
        date_from?.length > 0
          ? date_from
          : handleFormatDate(dateSevenDaysBefore, false, false, true),
      date_to:
        date_to?.length > 0
          ? date_to
          : handleFormatDate(new Date(), false, false, true),
      clinic,
      referenzperson,
      departement,
    };
    onClose();
    printCalendarDate(sendData)
      .then((resp) => onLoading(false))
      .catch((resp) => onLoading(false));
  };

  return (
    <StyledDownloadModal
      className="c-modal c-time-entry"
      data-action="click->pistachio--modal#onClick keydown@window->pistachio--modal#onKeyDown"
      data-controller="pistachio--modal"
      data-pistachio--remote-modal--handler-target="modal"
      data-show="false"
      style={{ display: "flex" }}
    >
      <div className="c-modal__inner">
        <div className="c-modal__content">
          <div className="animate-pulse flex space-x-4 w-64 hidden">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-6 bg-blue-200 rounded"></div>
              <div className="h-12 bg-blue-200 rounded"></div>
              <div className="flex flex-row gap-4">
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="form w-full lg:w-[22rem]">
              <div className="space-y-3">
                <div className="fields">
                  <input
                    type="date"
                    value={data.date_from}
                    onChange={(e) =>
                      handleChangeField("date_from", e.target.value)
                    }
                  />
                  <input
                    type="date"
                    value={data.date_to}
                    onChange={(e) =>
                      handleChangeField("date_to", e.target.value)
                    }
                  />
                </div>
                <input
                  type="text"
                  placeholder="Company name"
                  value={data.clinic}
                  onChange={(e) => handleChangeField("clinic", e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Referenzperson name"
                  value={data.referenzperson}
                  onChange={(e) =>
                    handleChangeField("referenzperson", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Departement name"
                  value={data.departement}
                  onChange={(e) =>
                    handleChangeField("departement", e.target.value)
                  }
                />
              </div>
              <div className="form-actions justify-between">
                <div className="flex">
                  <input
                    type="button"
                    id="new_time_entry"
                    name="commit"
                    value="Download"
                    className="c-button c-button--positive"
                    data-time-sheet--form-target="submitButton"
                    onClick={handleSubmit}
                  />
                  <button
                    type="button"
                    className="c-button c-button--secondary modal-close"
                    data-action="#close"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDownloadModal>
  );
};

const StyledDownloadModal = styled.div`
  .c-modal__inner {
    width: 95svw;
    max-width: 300px;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`;
