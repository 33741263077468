export const WeekHeader = () => (
  <>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Mon
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Tue
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Wed
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Thu
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Fri
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Sat
    </div>
    <div role="columnheader" class="fc-col-header-cell fc-day fc-day-sun">
      Sun
    </div>
  </>
);
