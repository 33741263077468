import styled from "styled-components";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { handleGetDays } from "../../../helpers";
import { Dates } from "./Dates";
import { HolidayModal } from "./HolidayModal";
import { getAbsences } from "../../../api/methods";

export const Calendar = ({ data, onRefreshData }) => {
  const [date, setDate] = useState(new Date());
  const [days, setDays] = useState(handleGetDays(new Date()));
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  useEffect(() => {
    setDays(handleGetDays(date));
  }, [date]);

  const handleClose = () => {
    setModal(null);
    setModalInfo(null);
  };

  return (
    <StyledCalendar className="box bg-white" id="dashboard_absencess">
      {(modal || modalInfo) && (
        <HolidayModal
          onClose={handleClose}
          date={modal}
          onSuccess={onRefreshData}
          data={modalInfo}
        />
      )}

      <div className="p-6 pb-4">
        <Header date={date} onChangeDate={(val) => setDate(val)} />
        <Dates
          days={days}
          onAddHoliday={(val) => setModal(val)}
          data={data}
          onOpenModalInfo={(val) => setModalInfo(val)}
        />
      </div>
    </StyledCalendar>
  );
};

const StyledCalendar = styled.div``;
