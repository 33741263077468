import styled from "styled-components";
import { Links } from "./Links";
import { Avatar } from "./Avatar";

export const Header = ({ user }) => {
  return (
    <StyledHeader>
      <div className="containerWrapper">
        <Links user={user} />
        <Avatar user={user} />
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  height: 64px;

  .containerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
  }
  padding: 1rem;
  background: #d6dee4;
  margin-bottom: 56px;
  @media (max-width: 500px) {
    .containerWrapper {
      padding-left: 0;
    }
  }
`;
