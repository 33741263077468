import styled from "styled-components";
import { HolidayTag } from "../../../components/HolidayTag";

export const Day = ({ day, active, onClick }) => (
  <StyledDay active={active} onClick={onClick}>
    <div className="number">{day}</div>
    {active && <HolidayTag />}
  </StyledDay>
);

const StyledDay = styled.div`
  border-radius: 0.25rem;
  border-width: 1px;
  padding: 0.25rem;
  text-align: right;
  font-size: 0.75rem;
  line-height: 1rem;
  background: ${({ active }) => (active ? "rgba(113, 192, 117, 0.2)" : "#FFF")};
  cursor: pointer;
  color: black;
  min-height: 40px;
  .number {
    padding: 4px;
    position: relative;
    z-index: 4;
  }
`;
