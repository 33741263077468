import styled from "styled-components";
import { handleFormatDate } from "../../../helpers";

export const Header = ({ date, onChangeDate }) => {
  const handlePrevMonth = () =>
    onChangeDate(new Date(date.setMonth(date.getMonth() - 1)));
  const handleNextMonth = () =>
    onChangeDate(new Date(date.setMonth(date.getMonth() + 1)));

  return (
    <StyledHeader>
      <div class="flex items-center text-lg text-gray-900 font-bold">
        <h2>Absences</h2>
        <div class="ml-auto">
          <div class="c-button-group">
            <button
              class="c-button c-button--icon-only c-button--sm c-button--inverted c-button--secondary change-month-btn"
              data-action="prev"
              onClick={handlePrevMonth}
            >
              <i class="icon3000 icon-angle-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="currentColor"
                >
                  <path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"></path>
                </svg>
              </i>
            </button>
            <button
              class="c-button c-button--icon-only c-button--sm c-button--inverted c-button--secondary change-month-btn"
              data-action="next"
              onClick={handleNextMonth}
            >
              <i class="icon3000 icon-angle-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="currentColor"
                >
                  <path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"></path>
                </svg>
              </i>
            </button>
          </div>
          {/* <button class="c-button add-absence-button c-button--positive c-button--sm c-button--inverted">
            <i class="icon3000 icon-plus">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
              >
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path>
              </svg>
            </i>
            Add
          </button> */}
        </div>
      </div>
      <div class="-mt-2 text-sm text-gray-500 calendar-title">
        {handleFormatDate(date, false, true)}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div``;
