import styled from "styled-components";
import { Header } from "./Header/Header";
import { Week } from "./Week/Week";
import { useEffect, useState } from "react";
import moment, { weekdays } from "moment";
import { handleGetToday } from "../../../helpers";

export const Calendar = ({
  activeDay,
  onChangeActiveDay,
  timers,
  readOnly,
  obsencesData,
}) => {
  const [date, setDate] = useState(handleGetToday());
  const [week, setWeek] = useState([]);

  const handleGetNextDay = (d, days) => {
    const day = new Date(d);

    day.setDate(day.getDate() + days);
    day.setHours(0);
    return day;
  };

  const handleGetWeek = (startDate) => {
    let data = [];
    for (let i = 0; i <= 6; i++) {
      data.push(Date.parse(handleGetNextDay(startDate, i)));
    }

    return data;
  };

  const handleGetCurrentWeek = (d, isSetFirstDay, isDateDate) => {
    const startOfWeek = moment(d).startOf("isoWeek").toDate();
    const weekDays = handleGetWeek(startOfWeek);

    setWeek(weekDays);
    isSetFirstDay && onChangeActiveDay(Date.parse(startOfWeek));
    isDateDate && setDate(startOfWeek);
  };

  const handleChangeDate = (val, isSetActivetDay) => {
    setDate(val);
    isSetActivetDay && onChangeActiveDay(Date.parse(val));
    handleGetCurrentWeek(val, !isSetActivetDay);
  };

  useEffect(() => {
    handleChangeDate(handleGetToday(), true);
  }, []);

  const handleChangeWeek = (isNext) => {
    const day = new Date(week[isNext ? 6 : 0]);
    day.setDate(isNext ? day.getDate() + 1 : day.getDate() - 1);
    handleGetCurrentWeek(day, true, true);
  };

  return (
    <StyledCalendar>
      <Header
        date={date}
        onChangeDate={handleChangeDate}
        activeDay={activeDay}
        onChangeWeek={handleChangeWeek}
        readOnly={readOnly}
      />
      <Week
        weekData={week}
        activeDay={activeDay}
        onChangeActiveDay={onChangeActiveDay}
        timers={timers}
        obsencesData={obsencesData}
      />
    </StyledCalendar>
  );
};

const StyledCalendar = styled.div``;
