import { useEffect } from "react";
import { addZero, handleGetHours, handleGetTimerValue } from "../../../helpers";
import { useStopwatch } from "react-timer-hook";
import styled from "styled-components";

export const Card = ({
  activeDay,
  active,
  onEdit,
  date,
  dateStart,
  endDate,
  onStopTimer,
  onAddTimer,
  readOnly,
  isPause,
  onDelete,
}) => {
  const { totalSeconds, seconds, minutes, hours, days, pause, reset } =
    useStopwatch({
      autoStart: true,
      offsetTimestamp: date,
    });

  useEffect(() => {
    if (!active) {
      pause();
    }
  }, [active]);

  const handleGetOffSet = () => {
    const now = new Date();
    // if (activeDay > now?.getTime()) {
    //   now.setDate(new Date(activeDay)?.getDate());
    // }
    const dateStartSeconds = date?.getTime();
    return (now?.getTime() - dateStartSeconds) / 1000;
  };

  useEffect(() => {
    let stopwatchOffset = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin",
      })
    );
    if (
      dateStart?.getTime() !== date?.getTime() &&
      dateStart?.getTime() > new Date()?.getTime()
    ) {
      const secondsmore = (dateStart?.getTime() - date?.getTime()) / 1000;
      stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsmore);
    }
    stopwatchOffset.setSeconds(
      stopwatchOffset.getSeconds() + handleGetOffSet()
    );
    reset(stopwatchOffset, true);
  }, [date]);

  return (
    <StyledCard className="c-ts_entries" isPause={isPause}>
      <div
        className="c-ts_entries_time-entry w-full"
        data-time-sheet-target="timeEntry"
      >
        <div className="flex justify-center relative w-full card-main-content">
          <div className="flex flex-col items-start w-full sm:w-3/5">
            <div className="c-ts_entries_time-entry__timestamps">
              <div
                className="inline-block text-gray-600 text-xs font-medium bg-gray-200 rounded px-1 cursor-help"
                data-tooltip="Created at: Thu, 11 January 2024, 05:57<br> Last updated at: Thu, 11 January 2024, 06:19"
              >
                {handleGetHours(date, true)} -{" "}
                {endDate ? handleGetHours(endDate, true) : null}
              </div>
            </div>
            <div className="flex flex-col sm:max-w-full">
              {isPause ? null : (
                <div className="c-ts_entries_time-entry__meta">
                  <div className="c-ts_entries_time-entry__project">
                    <span>Intern</span>
                    <span className="ml-1">(Persanesth)</span>
                  </div>
                </div>
              )}
              <div className="c-ts_entries_time-entry__title">
                {isPause ? "Pause" : "Administration"}
              </div>
              <div className="c-ts_entries_time-entry__meta">
                <div className="c-ts_entries_time-entry__note">
                  <p className="break-all"></p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center sm:w-2/5">
            <div className="c-ts_entries_time-entry__actions">
              {readOnly ? null : active ? (
                <button
                  className="c-button c-button--sm c-button--primary c-ts_timer-button c-ts_timer-button--running c-ts_timer-button__stop button_to"
                  type="button"
                  onClick={onStopTimer}
                >
                  <div className="c-ts_timer-button__icon">
                    <div className="c-ts_timer-button__icon__circle">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                      >
                        <circle
                          stroke-width="9%"
                          fill="transparent"
                          cy="50%"
                          cx="50%"
                          r="45%"
                        ></circle>
                      </svg>
                    </div>
                    <div className="c-ts_timer-button__icon__hand">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                      >
                        <line
                          x1="50%"
                          y1="50%"
                          x2="50%"
                          y2="20%"
                          stroke-width="9%"
                          stroke-linecap="round"
                        ></line>
                      </svg>
                    </div>
                  </div>
                  <span className="c-ts_timer-button__label">Stop</span>
                </button>
              ) : true ? null : (
                <button
                  data-disable="true"
                  className="c-button c-button--sm c-button--secondary c-button--inverted sm:ml-3 c-ts_timer-button c-ts_timer-button__start button_to"
                  type="button"
                  onClick={onAddTimer}
                >
                  <div className="c-ts_timer-button__icon">
                    <div className="c-ts_timer-button__icon__circle">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                      >
                        <circle
                          stroke-width="9%"
                          fill="transparent"
                          cy="50%"
                          cx="50%"
                          r="45%"
                        ></circle>
                      </svg>
                    </div>
                    <div className="c-ts_timer-button__icon__hand">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                      >
                        <line
                          x1="50%"
                          y1="50%"
                          x2="50%"
                          y2="20%"
                          stroke-width="9%"
                          stroke-linecap="round"
                        ></line>
                      </svg>
                    </div>
                  </div>
                  <span className="c-ts_timer-button__label sm:hidden">
                    Start
                  </span>
                </button>
              )}

              <div className="c-ts_entries_time-entry__duration">
                <span className="duration" data-hhmm-duration="0:22">
                  {endDate ? (
                    handleGetTimerValue(date, endDate)
                  ) : (
                    <>
                      {addZero(hours + days * 24)}:{addZero(minutes)}:
                      {addZero(seconds)}
                    </>
                  )}
                </span>
              </div>
              {readOnly ? null : active ? null : (
                <div className="card-actions">
                  <div className=" text-right sm:w-auto sm:ml-1 sm:order-2">
                    <a
                      className="c-button c-button--sm c-button--secondary c-button--inverted c-button--icon-only c-ts_entries_time-entry__edit edit-time-entry"
                      onClick={onEdit}
                    >
                      <i className="icon3000 icon-pencil">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                        >
                          <path d="M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c10.4-3 20.1-8 28.6-14.5l.3 .2 .5-.8c1.4-1.1 2.7-2.2 4-3.3c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-2.5 2.5-4.9 5.2-7.1 8l-.8 .5 .2 .3c-6.5 8.5-11.4 18.2-14.5 28.6zM383 191L197.4 376.6l-49.6-12.4-12.4-49.6L321 129 383 191zM97 358.9l7.7 31c2.1 8.6 8.9 15.3 17.5 17.5l31 7.7-7.4 11.2c-2.6 1.4-5.3 2.6-8.1 3.4l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4c.8-2.8 2-5.6 3.4-8.1L97 358.9zM315.3 218.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z"></path>
                        </svg>
                      </i>
                    </a>
                  </div>
                  <div className=" text-right sm:w-auto sm:ml-1 sm:order-2">
                    <a
                      className="c-button c-button--sm c-button--secondary c-button--inverted c-button--icon-only c-ts_entries_time-entry__edit edit-time-entry"
                      onClick={onDelete}
                    >
                      <svg
                        fill="#374151"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 482.428 482.429"
                        xmlSpace="preserve"
                        className="delete-icon"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path>{" "}
                              <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path>{" "}
                              <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path>{" "}
                              <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  ${({ isPause }) =>
    isPause &&
    `
    .c-ts_entries_time-entry {
    background: #f3f3f3;
  }
  `}
  .duration {
    white-space: nowrap;
  }
  .card-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 10px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 30px;
      height: 30px;
      i {
        padding: 0 !important;
      }
    }
    .delete-icon {
      height: 20px;
      width: 20px;
    }
  }
  @media (max-width: 600px) {
    .card-main-content {
      flex-direction: column;
    }
    .c-ts_entries_time-entry__actions {
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
`;
