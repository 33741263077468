import { useState } from "react";
import styled from "styled-components";
import { Empty } from "./Empty";
import { Card } from "./Card";
import { Total } from "./Total";
import { handleGetTimerValue, handleGetTotalSeconds } from "../../../helpers";

export const Timers = ({
  activeDay,
  timers,
  onStopTimer,
  onAddTimer,
  readOnly,
  onStopPause,
  isPause,
  onDelete,
  onEdit,
}) => {
  const handleToggleTimer = () => {};

  return timers?.length === 0 ? (
    <Empty />
  ) : (
    <StyledTimers>
      <div className="w-full">
        {timers?.map(
          ({ date_create, date_start, date_end, id, timer_type }, i) => (
            <Card
              key={i}
              onEdit={() => onEdit({ date_create, date_end, id, timer_type })}
              onToggle={handleToggleTimer}
              date={new Date(date_create)}
              dateStart={new Date(date_start)}
              endDate={date_end ? new Date(date_end) : null}
              active={!date_end}
              onStopTimer={() =>
                timer_type === 2 ? onStopPause(id) : onStopTimer(id)
              }
              onAddTimer={() => onAddTimer(id)}
              readOnly={readOnly}
              isPause={timer_type === 2}
              onDelete={() => onDelete(id)}
              activeDay={activeDay}
            />
          )
        )}
        <Total
          total={handleGetTotalSeconds(
            timers?.filter((d) => d.timer_type !== 2)
          )}
          totalPause={handleGetTotalSeconds(
            timers?.filter((d) => d.timer_type !== 1)
          )}
        />
      </div>
    </StyledTimers>
  );
};

const StyledTimers = styled.div``;
