import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const Avatar = ({ user }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location = "/#/auth";
    window.location.reload(true);
  };

  return (
    <StyledAvatar avatar={user?.avatar}>
      {user?.avatar?.length > 0 ? null : (
        <>
          {user?.first_name?.toUpperCase()?.[0]}{" "}
          {user?.last_name?.toUpperCase()?.[0]}
        </>
      )}
      <div className="c-dropdown__window">
        <div className="c-dropdown__content">
          <div className="c-header__dropdown__title">{user?.first_name}</div>
          <div className="c-dropdown__links c-header__dropdown-links">
            <div onClick={() => navigate("/profile")}>My Profile</div>
            <hr />
            <div onClick={handleLogout}>Logout</div>
          </div>
        </div>
      </div>
    </StyledAvatar>
  );
};

const StyledAvatar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: url(${({ avatar }) => avatar}) center/cover no-repeat, #9fa6b2;
  font-family: "Arial-BoldMT", "Arial", sans-serif;
  font-weight: 700;
  color: #ffffffc9;
  font-size: 14px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  position: relative;
  .c-dropdown__window {
    right: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
  }
  .c-dropdown__links div {
    text-align: left;
    text-transform: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / 1);
    line-height: 1.25rem;
    font-weight: 400;
    &:hover {
      color: #fff;
      background: #477dca;
    }
  }
  &:focus {
    .c-dropdown__window {
      visibility: visible;
      opacity: 1;
    }
  }
`;
