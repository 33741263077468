import { useEffect, useRef, useState } from "react";
import { HolidayTag } from "../../components/HolidayTag";
import { SmallAvatar } from "../../components/SmallAvarar";
import { getAbsences } from "../../api/methods";
import { handleFormatDate } from "../../helpers/index";
import printIcon from "../../assets/print.svg";
import styled from "styled-components";

export const Row = ({ user, days, onOpenHoliday, onPrint }) => {
  const [data, setData] = useState([]);
  const isLoading = useRef(false);

  const handleGetData = () => {
    if (user?.id) {
      isLoading.current = true;

      getAbsences(user?.id).then((resp) => {
        isLoading.current = false;
        setData(resp?.data?.absences);
      });
    }
  };

  useEffect(() => {
    !isLoading.current && handleGetData();
  }, [user, days]);

  return (
    <>
      <StyledRow className="calendar-row calendar-row--first">
        <SmallAvatar
          name={user?.first_name}
          lastName={user?.last_name}
          avatar={user?.avatar}
          className="small-avatar"
        />
        <div class="ml-1 text-sm whitespace-nowrap truncate">
          {user?.first_name} {user?.last_name}
        </div>
        <img src={printIcon} alt="" onClick={() => onPrint(user?.id)} />
      </StyledRow>
      <div>
        <div
          className="days"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${days?.length}, 1fr)`,
          }}
        >
          {days?.map((d, key) => {
            const active = data?.find(
              (dt) =>
                handleFormatDate(new Date(dt?.data_weekend)) ===
                handleFormatDate(d)
            );
            return (
              <div
                key={key}
                className={`calendar-row ${active && "calendar-row-active"}`}
                onClick={active ? () => onOpenHoliday(active) : null}
              >
                {active && <HolidayTag />}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const StyledRow = styled.div`
  img {
    height: 30px;
    margin-left: auto;
    cursor: pointer;
  }
`;
