export const Total = ({ total }) => {
  return (
    <div className="p-2 bg-gray-100 flex flex-col items-center">
      <div className="font-medium text-gray-800">Total</div>
      <div>
        <span style={{ color: " gray" }} className="duration">
          {total}
        </span>
      </div>
    </div>
  );
};
