import styled from "styled-components";
import icon1 from "../assets/diplom.svg";
import icon2 from "../assets/certificate.svg";
import icon3 from "../assets/password.svg";
import icon4 from "../assets/cv.svg";
import icon5 from "../assets/file.svg";

export const FilesList = ({ data, small }) => {
  return (
    <StyledFilesList className={`${small && "small"} noOpen filesListWrapper`}>
      {data?.diplom?.length > 0 ? (
        <a
          href={data?.diplom}
          download
          title="Diplom"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon1} alt="" className="noOpen" />
        </a>
      ) : null}
      {data?.certificate?.length > 0 ? (
        <a
          href={data?.certificate}
          download
          title="Certificate"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon2} alt="" className="noOpen" />
        </a>
      ) : null}

      {data?.passport_1?.length > 0 ? (
        <a
          href={data?.passport_1}
          download
          title="Passport front"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon3} alt="" className="noOpen" />
        </a>
      ) : null}

      {data?.passport_2?.length > 0 ? (
        <a
          href={data?.passport_2}
          download
          title="Passport back"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon3} alt="" className="noOpen" />
        </a>
      ) : null}
      {data?.cv?.length > 0 ? (
        <a
          href={data?.cv}
          download
          title="CV"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon4} alt="" className="noOpen" />
        </a>
      ) : null}
      {data?.other_files?.length > 0 ? (
        <a
          href={data?.other_files}
          download
          title="Other files"
          target="_blank"
          rel="noreferrer"
          className="noOpen"
        >
          <img src={icon5} alt="" className="noOpen" />
        </a>
      ) : null}
    </StyledFilesList>
  );
};

const StyledFilesList = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 20px;
  flex-shrink: 0;
  img {
    height: 25px;
  }
  &.small {
    margin-right: 0px;
    margin-top: 10px;
    img {
      height: 18px;
    }
  }
`;
