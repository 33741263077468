import styled from "styled-components";
import { Header } from "../../components/Header/Header";
import { Banner } from "./Banner/Banner";
import { Tracking } from "../../components/Tracking/Tracking";
import { Calendar } from "./Calendar/Calendar";
import { useEffect, useState } from "react";
import { getAbsences } from "../../api/methods";

export const Dashboard = ({ user, onUpdatedUserData }) => {
  const [data, setData] = useState([]);

  const handleGetData = () =>
    user?.id &&
    getAbsences(user?.id).then((resp) => setData(resp?.data?.absences));

  useEffect(() => {
    handleGetData();
  }, [user]);

  
  return (
    <StyledDashboard className="containerWrapper">
      <Banner user={user} onUpdatedUserData={onUpdatedUserData} />
      <div className="dashboard-wrapper">
        <Tracking obsencesData={data} />
        <Calendar data={data} onRefreshData={handleGetData} />
      </div>
    </StyledDashboard>
  );
};

const StyledDashboard = styled.div`
  padding: 0 32px;
  .dashboard-wrapper {
    gap: 32px;
    display: grid;
    grid-template-columns: 1fr 380px;
    /* grid-template-columns: 1fr; */
  }
  @media (max-width: 1200px) {
    .dashboard-wrapper {
      grid-template-columns: 1fr;
    }
  }
`;
