import styled from "styled-components";
import { handleFormatDate, handleGetToday } from "../../helpers";

export const Header = ({
  activeDay,
  onAddTimerModal,
  onAddTimer,
  isTimer,
  onPause,
}) => {
  return (
    <StyledHeader className="flex items-center">
      <div className="flex items-baseline gap-4">
        <div>
          <h2 className="text-lg font-bold text-gray-900">Time Tracking</h2>
        </div>
      </div>
      <div className="ml-auto flex items-center space-x-2 header-btns-wrappers">
        <>
          {!isTimer ? (
            <button
              className="c-button c-button--secondary c-button--sm c-button--inverted g-gs-highlight c-ts_timer-button c-ts_timer-button__start button_to"
              type="button"
              onClick={onPause}
            >
              <div className="c-ts_timer-button__icon">
                <div className="c-ts_timer-button__icon__circle pauseIcon">
                  <svg
                    viewBox="-1 0 8 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    style={{ height: 15 }}
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>pause [#1006]</title>
                      <desc>Created with Sketch.</desc> <defs> </defs>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-227.000000, -3765.000000)"
                          fill="#374151"
                        >
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            <path
                              d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606"
                              id="pause-[#1006]"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <span className="c-ts_timer-button__label">Start pause</span>
            </button>
          ) : null}
          <button
            className="c-button c-button--secondary c-button--sm c-button--inverted g-gs-highlight c-ts_timer-button c-ts_timer-button__start button_to"
            type="button"
            onClick={() => onAddTimer()}
            disabled={isTimer}
          >
            <div className="c-ts_timer-button__icon">
              <div className="c-ts_timer-button__icon__circle">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                >
                  <circle
                    stroke-width="9%"
                    fill="transparent"
                    cy="50%"
                    cx="50%"
                    r="45%"
                  ></circle>
                </svg>
              </div>
              <div className="c-ts_timer-button__icon__hand">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                >
                  <line
                    x1="50%"
                    y1="50%"
                    x2="50%"
                    y2="20%"
                    stroke-width="9%"
                    stroke-linecap="round"
                  ></line>
                </svg>
              </div>
            </div>
            <span className="c-ts_timer-button__label">Start Timer</span>
          </button>
        </>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  .pauseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    .header-btns-wrappers {
      width: 100%;
      margin-top: 10px;
      button {
        width: 100%;
      }
    }
  }
`;
