import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Links = ({ user }) => {
  return (
    <StyledLinks>
      <NavLink to="/dashboard" activeClassName="active">
        Dashboard
      </NavLink>
      <NavLink to="/calendar" activeClassName="active">
        Calendar
      </NavLink>
      {user?.role_id === 1 ? (
        <NavLink to="/staff" activeClassName="active">
          Staff
        </NavLink>
      ) : null}
    </StyledLinks>
  );
};

const StyledLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  a {
    display: flex;
    align-items: baseline;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #466ea5;
    transition: all 0.3s;
    &:hover {
      background: rgb(118 159 215 / 0.1);
    }
    &.active {
      background: rgb(64 113 182 / 0.1);
    }
    @media (max-width: 500px) {
      padding: 5px 8px;
    }
  }
`;
