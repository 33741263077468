import styled from "styled-components";
import icon from "../../assets/upload.svg";
import loadingIcon from "../../assets/loading-white.svg";
import { updateUserAvatar } from "../../api/methods";
import { useState } from "react";
import cogoToast from "cogo-toast";

export const Avatar = ({ data, isEdit, onUpdateUser }) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateAvatar = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const data = new FormData();

      data.append("avatar", e.target.files[0]);
      setLoading(true);
      updateUserAvatar(data)
        .then((resp) => {
          setLoading(false);
          onUpdateUser({ avatar: resp?.data?.avatar });
        })
        .catch(() => {
          setLoading(false);
          cogoToast.error("Error");
        });
    }
  };

  return (
    <StyledAvatar
      className="avatar"
      isEdit={isEdit}
      avatar={data?.avatar}
      loading={loading}
    >
      {loading ? (
        <div className="loading">
          <img src={loadingIcon} alt="" />
        </div>
      ) : null}
      <img src={icon} alt="" className="upload-icon" />
      {data?.avatar?.length > 0 ? null : (
        <div className="text">
          {data?.first_name?.toUpperCase()?.[0]}
          {data?.last_name?.toUpperCase()?.[0]}
        </div>
      )}
      {isEdit && !loading ? (
        <input
          type="file"
          value=""
          accept="image/*"
          onChange={handleUpdateAvatar}
        />
      ) : null}
    </StyledAvatar>
  );
};

const StyledAvatar = styled.div`
  position: relative;
  background: url(${({ avatar }) => avatar}) center/cover no-repeat, #9fa6b2 !important;
  .upload-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
    z-index: 1;
    cursor: pointer;
  }
  .loading {
    img {
      height: 40px;
      position: relative;
      z-index: 10;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #00000073;
      border-radius: 100%;
    }
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }
  input[type="file"]::file-selector-button {
    visibility: hidden;
  }
  .text {
    transition: all 0.3s;
  }
  ${({ isEdit, loading }) =>
    isEdit &&
    !loading &&
    `
     &:hover {
    img {
      opacity: 1;
    }
    .text {
      opacity: 0;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right:0;
        bottom: 0;
        left: 0;
        background: #00000073;
        border-radius: 100%;
    }
  }
  
 `}
`;
