export const Header = () => (
  <>
    {" "}
    <thead className="bg-gray-100 sticky top-0 z-10">
      <tr>
        <th className="w-16"></th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">First Name</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">Last Name</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">Position</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">Department</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">Team</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          <span className="text-gray-600">Email address</span>
        </th>
        <th
          className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        ></th>
      </tr>
    </thead>
  </>
);
