import { useEffect, useState } from "react";
import { handleFormatDate } from "../../../helpers";
import { saveObsences } from "../../../api/methods";
import cogoToast from "cogo-toast";

export const HolidayModal = ({ onClose, date, onSuccess, data }) => {
  const [comment, setComment] = useState("");

  const handleAdd = () => {
    saveObsences({
      data_absence: handleFormatDate(date, true),
      comment: comment?.length > 0 ? comment : handleFormatDate(date, true),
    })
      .then((resp) => {
        onClose();
        cogoToast.success("Success");
        onSuccess();
      })
      .catch(() => {
        onClose();

        cogoToast.error("Error");
      });
  };

  useEffect(() => {
    setComment(data?.comment);
  }, [data]);

  return (
    <div
      class="c-modal c-time-entry"
      data-action="click->pistachio--modal#onClick keydown@window->pistachio--modal#onKeyDown"
      data-controller="pistachio--modal"
      data-pistachio--remote-modal--handler-target="modal"
      data-show="false"
      style={{ display: "flex" }}
    >
      <div
        class="c-modal__inner"
        style={{ width: "100%", maxWidth: 740, paddingTop: 90 }}
      >
        <div class="c-modal__content">
          <div class="animate-pulse flex space-x-4 w-64 hidden">
            <div class="flex-1 space-y-4 py-1">
              <div class="h-6 bg-blue-200 rounded"></div>
              <div class="h-12 bg-blue-200 rounded"></div>
              <div class="flex flex-row gap-4">
                <div class="h-8 bg-blue-200 rounded w-1/2"></div>
                <div class="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="c-modal__title">
              {data ? "" : "Add"} Absence{" "}
              <div class="c-modal__title__sub">
                {handleFormatDate(data ? data?.data_weekend : date)}
              </div>
            </div>
            <form class="form c-absence-form lg:w-[28rem]">
              <div class="space-y-1">
                <div class="pt-2">
                  <div class="flex flex-col items-start">
                    <textarea
                      placeholder="Note (optional)"
                      id="absence_note_881b00fa"
                      name="absence[note]"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      readOnly={!!data}
                      style={{ resize: "none", height: 73 }}
                    ></textarea>
                  </div>
                </div>
              </div>

              {data ? (
                <div class="form-actions justify-between">
                  <div class="flex">
                    <button
                      type="button"
                      class="c-button c-button--secondary modal-close"
                      data-action="#close"
                      href="javascript:;"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              ) : (
                <div class="form-actions justify-between">
                  <div class="flex">
                    <input
                      type="button"
                      id="new_absence"
                      name="commit"
                      value="Request"
                      class="c-button ml-0"
                      onClick={handleAdd}
                    />
                    <button
                      type="button"
                      class="c-button c-button--secondary modal-close"
                      data-action="#close"
                      href="javascript:;"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
