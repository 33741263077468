import { useState } from "react";
import { handleFormatDate } from "../../../helpers";
import { updateUserFiles } from "../../../api/methods";
import cogoToast from "cogo-toast";

export const FilesModal = ({ onClose, onUpdatedUserData }) => {
  const [data, setData] = useState({ type: "diplom" });

  const handleChangeField = (field, value) =>
    setData({ ...data, [field]: value });

  const handleAdd = () => {
    if (data?.file) {
      const { type, file } = data;
      const sendData = new FormData();

      sendData.append(type, file);
      updateUserFiles(sendData)
        .then((resp) => {
          onUpdatedUserData();
          onClose();
          cogoToast.success("Success");
        })
        .catch(() => {
          cogoToast.error("Error");
        });
    }
  };

  return (
    <div className="c-modal c-time-entry" style={{ display: "flex" }}>
      <div className="c-modal__inner">
        <div className="c-modal__content">
          <div className="animate-pulse flex space-x-4 w-64 hidden">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-6 bg-blue-200 rounded"></div>
              <div className="h-12 bg-blue-200 rounded"></div>
              <div className="flex flex-row gap-4">
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="c-modal__title"> Upload files</div>
            <div className="form w-full lg:w-[22rem]">
              <input
                id="time_entry_date"
                autocomplete="off"
                type="hidden"
                value="2024-04-19"
                name="time_entry[date]"
              />
              <div className="space-y-3">
                <div className="flex">
                  <div className="w-full grid grid-cols-1 space-y-2">
                    <span
                      className="select2 select2-container select2-container--default"
                      style={{ width: 230 }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-disabled="false"
                          aria-labelledby="select2-time_entryproject_id-yw-container"
                          aria-controls="select2-time_entryproject_id-yw-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-time_entryproject_id-yw-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Intern"
                          >
                            File type
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span
                        className="dropdown-wrapper"
                        aria-hidden="true"
                      ></span>
                    </span>
                    <select
                      className="js-example-basic-single select2-hidden-accessible"
                      name="time_entry[task_id]"
                      value={data?.type}
                      onChange={(e) =>
                        handleChangeField("type", e.target.value)
                      }
                    >
                      <option
                        value="diplom"
                        data-select2-id="select2-data-6-hdr5"
                      >
                        Diplom
                      </option>
                      <option value="certificate">Certificate</option>
                      <option value="passport_1">Passport front</option>
                      <option value="passport_2">Passport back</option>
                      <option value="cv">Cv</option>
                      <option value="other_files">Other Files</option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--default"
                      dir="ltr"
                      data-select2-id="select2-data-5-hflh"
                      style={{ width: 230 }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-disabled="false"
                          aria-labelledby="select2-time_entrytask_id-ji-container"
                          aria-controls="select2-time_entrytask_id-ji-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-time_entrytask_id-ji-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Administration"
                          >
                            File
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span
                        className="dropdown-wrapper"
                        aria-hidden="true"
                      ></span>
                    </span>
                    <input
                      type="file"
                      name=""
                      id=""
                      onChange={(e) =>
                        setData({ ...data, file: e.target?.files?.[0] })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-actions justify-between">
                <div className="flex">
                  <input
                    type="button"
                    id="new_time_entry"
                    name="commit"
                    value="Upload"
                    className="c-button c-button--positive"
                    data-time-sheet--form-target="submitButton"
                    onClick={handleAdd}
                  />
                  <button
                    type="button"
                    className="c-button c-button--secondary modal-close"
                    data-action="#close"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
