import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { FilesList } from "../../components/FilesList";
import { SmallAvatar } from "../../components/SmallAvarar";
import printIcon from "../../assets/print.svg";

export const Row = ({
  name,
  lastName,
  position,
  department,
  team,
  email,
  id,
  onDelete,
  onOpenInfo,
  active,
  data,
  onPrint,
}) => {
  const navigate = useNavigate();

  return (
    <StyledRow
      onClick={(e) =>
        e.target.classList?.contains("noOpen") ? null : onOpenInfo()
      }
      className={`${active && "active"}`}
    >
      <td>
        <SmallAvatar name={name} lastName={lastName} avatar={data?.avatar} />
      </td>
      <td className="text-gray-900">{name ?? "-"}</td>
      <td className="text-gray-900">{lastName ?? "-"}</td>
      <td className="text-gray-900">{position ?? "-"}</td>
      <td className="text-gray-900">{department ?? "-"}</td>
      <td className="text-gray-900">{team ?? "-"}</td>
      <td className="text-gray-900">
        <div> {email ?? "-"}</div>
        <div className="noOpen">
          <FilesList data={data} small />
        </div>
      </td>
      <td className="noOpen">
        <div className="actions">
          <img
            src={printIcon}
            className=" noOpen edit-icon"
            alt=""
            onClick={() => onPrint(id)}
          />
          <img
            src={editIcon}
            alt=""
            className=" noOpen delete-icon"
            onClick={() => navigate(`/worker/${id}`)}
          />
          <img
            src={deleteIcon}
            alt=""
            className=" noOpen delete-icon"
            onClick={onDelete}
          />
        </div>
      </td>
    </StyledRow>
  );
};
const StyledRow = styled.tr`
  border-bottom: 1px solid rgb(210 214 220 / 1);
  cursor: pointer;
  &.active {
    background: rgb(226 226 227) !important;
    border-bottom: none;
  }
  td {
    color: #161e2e;
    font-size: 14px;
    padding: 12px 24px;
  }
  .delete-icon {
    cursor: pointer;
    height: 15px;
    margin-left: 3px;
  }
  .edit-icon {
    cursor: pointer;
    height: 30px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: max-content;
  }
`;
