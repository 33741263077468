import styled from "styled-components";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { Table } from "./Table";
import { handleGetDays } from "../../helpers";
import { getUsers } from "../../api/methods";
import loader from "../../assets/loading.svg";
import { DownloadModal } from "../../components/DownloadModal/DownloadModal";

export const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [days, setDays] = useState(handleGetDays(new Date()));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadModal, setDownloadModal] = useState(false);

  const handleGetUsers = () => {
    setLoading(true);
    getUsers().then((resp) => {
      setLoading(false);
      setUsers(resp?.data?.users ?? []);
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    setDays(handleGetDays(date));
  }, [date]);

  return (
    <StyledCalendar className="containerWrapper">
      {downloadModal && (
        <DownloadModal
          onClose={() => setDownloadModal(null)}
          onLoading={(val) => setLoading(val)}
          userId={downloadModal}
        />
      )}

      {loading ? (
        <img src={loader} alt="" className="loading" />
      ) : (
        <>
          <Header date={date} onChangeDate={(val) => setDate(val)} />
          <Table
            days={days}
            users={users}
            onPrint={(id) => setDownloadModal(id?.toString())}
          />
        </>
      )}
    </StyledCalendar>
  );
};

const StyledCalendar = styled.div`
  padding: 0 32px;
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
  .loading {
    margin: 30px auto;
    height: 40px;
  }
`;
