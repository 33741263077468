import styled from "styled-components";
import flatpickr from "flatpickr";
import { useEffect, useRef } from "react";
import { handleFormatDate, handleGetToday } from "../../../../helpers";
import { TodayButton } from "./TodayButton";

export const Header = ({
  date,
  onChangeDate,
  activeDay,
  onChangeWeek,
  readOnly,
}) => {
  const calendar = useRef();

  useEffect(() => {
    calendar.current = flatpickr(".flatpickrInput", {
      onChange: (val) => onChangeDate(val?.[0], true),
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
    });
  }, []);

  useEffect(() => {
    calendar.current.setDate && calendar.current.setDate(new Date(activeDay));
  }, [activeDay]);

  return (
    <StyledHeader>
      <div className="flex mt-4">
        <div className="w-full flex flex-col">
          <div className="flex justify-between items-center px-0 py-2 tracking-calendar-header">
            <div className="flex items-center text-gray-800 text-sm font-medium">
              <span className="flex items-center">
                <span className="font-semibold ">Today:</span>
                <div className="ml-2">{handleFormatDate(activeDay)}</div>
              </span>
            </div>
            <div className="calendar-actions">
              <div className="flex">
                {handleFormatDate(handleGetToday()) ===
                handleFormatDate(activeDay) ? null : (
                  <TodayButton
                    onClick={() => onChangeDate(handleGetToday(), true)}
                  />
                )}
                <div className="c-button-group">
                  <a
                    className="c-button c-button--secondary c-button--sm c-button--inverted c-button--icon-only change-week-btn"
                    onClick={() => onChangeWeek()}
                  >
                    <i className="icon3000 icon-angle-left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        fill="currentColor"
                      >
                        <path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"></path>
                      </svg>
                    </i>
                  </a>
                  <div className="relative">
                    <div
                      className="flatpickr flatpickr-input"
                      readonly="readonly"
                    >
                      <input
                        type="hidden"
                        placeholder="Select Date.."
                        data-input=""
                        className="flatpickrInput"
                      />
                      <a
                        className="input-button c-selector-calendar__jump-to-date c-button c-button--secondary c-button--inverted c-button--sm c-button--icon-only text-gray-500 rounded-none"
                        title="toggle"
                        data-toggle=""
                      >
                        <i className="icon3000 icon-calendar-days">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                          >
                            <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"></path>
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                  <a
                    className="c-button c-button--secondary c-button--sm c-button--inverted c-button--icon-only change-week-btn"
                    onClick={() => onChangeWeek(true)}
                  >
                    <i className="icon3000 icon-angle-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        fill="currentColor"
                      >
                        <path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"></path>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  .flatpickrInput {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .c-button-group {
    flex-shrink: 0;
  }
  .calendar-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .holidayBtn {
    width: 100px;
  }
  @media (max-width: 500px) {
    .tracking-calendar-header {
      flex-direction: column;
      gap: 10px;
    }
  }
`;
