import { useRef, useState } from "react";
import styled from "styled-components";
import { FilesModal } from "./FilesModal";
import { FilesList } from "../../../components/FilesList";

export const Banner = ({ user, onUpdatedUserData }) => {
  const [openFilesModal, setOpenFilesModal] = useState(false);

  return (
    <StyledBanner avatar={user?.avatar}>
      {openFilesModal ? (
        <FilesModal
          onClose={() => setOpenFilesModal(false)}
          onUpdatedUserData={onUpdatedUserData}
        />
      ) : null}
      <div className="user">
        <div className="avatar">
          {user?.avatar?.length > 0 ? null : (
            <>
              {user?.first_name?.toUpperCase()?.[0]}
              {user?.last_name?.toUpperCase()?.[0]}
            </>
          )}
        </div>
        <span className="text-2xl font-bold text-gray-900 leading-8">
          {user?.first_name}
          <span className="ml-2">{user?.last_name}</span>
        </span>
      </div>
      <div className="mt-0 flex justify-center banner-actions-wrapper">
        <FilesList data={user} />
        <div className="upload-files" onClick={() => setOpenFilesModal(true)}>
          <span className="c-button c-button--secondary c-button--sm c-button--inverted">
            Upload files
          </span>
        </div>
        <a
          className="c-button c-button--secondary c-button--sm c-button--inverted"
          href="/#/profile"
        >
          My Profile
        </a>
      </div>
    </StyledBanner>
  );
};

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid #e5e7eb;
  margin-bottom: 32px;
  .user {
    display: flex;
    align-items: center;
  }
  .avatar {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background: url(${({ avatar }) => avatar}) center/cover no-repeat, #9fa6b2;
    font-family: "Arial-BoldMT", "Arial", sans-serif;
    font-weight: 700;
    color: #ffffffc9;
    font-size: 24px;
  }
  .upload-files {
    position: relative;
    margin-right: 10px;
    span {
      width: 150px;
    }
    cursor: pointer;
    span {
      cursor: pointer;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 500px) {
    .banner-actions-wrapper {
      flex-direction: column;
      width: 100%;
      .c-button {
        width: 100%;
        margin-top: 10px;
      }
    }
    .upload-files,
    .filesListWrapper {
      justify-content: center;
      margin: 0;
    }
  }
`;
