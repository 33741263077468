import { useState } from "react";
import { login } from "../../api/methods";
import cogoToast from "cogo-toast";

export const LoginForm = ({ onForgotPassword }) => {
  const [data, setData] = useState({ email: "", password: "" });

  const handleChangeField = (field, value) =>
    setData({ ...data, [field]: value });

  const handleLogin = () => {
    login(data)
      .then((resp) => {
        const msg = resp?.data?.msg;
        const token = resp?.data.access_token;
        if (!token) {
          cogoToast.error(msg ?? "Error");
        } else {
          localStorage.setItem("token", `Bearer ${token}`);
          window.location = "/#/dashboard";
        }
      })
      .catch((resp) => {
        const errors = resp?.response?.data?.errors
          ? Object?.entries(resp?.response?.data?.errors)?.map((e) => e[1]?.[0])
          : [];
        if (errors?.length > 0) {
          errors?.map((err) => cogoToast.error(err ?? "Error"));
        } else {
          cogoToast.error("Error");
        }
      });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <p className="login-title">Login</p>
      <div className="inp-field">
        <label for="em-addr">E-Mail-Adresse</label>
        <input
          id="em-addr"
          type="email"
          placeholder="E-Mail-Adresse"
          name="email"
          required=""
          value={data?.email}
          onChange={(e) => handleChangeField("email", e.target.value)}
        />
      </div>
      <div className="inp-field">
        <label for="pass">Passwort</label>
        <input
          id="pass"
          type="password"
          placeholder="Passwort"
          name="pass"
          required=""
          value={data?.password}
          onChange={(e) => handleChangeField("password", e.target.value)}
        />
      </div>
      <div className="inp-field">
        <a onClick={onForgotPassword}>Passwort vergessen</a>
      </div>
      <div className="text-center" onClick={handleLogin}>
        <input type="submit" value="Login" />
      </div>
    </form>
  );
};
