import styled from "styled-components";

export const Field = ({ label, value, edit, onChange }) => (
  <StyledField>
    <div className="label">{label}</div>
    <div className="value">
      {edit ? (
        <div className="flex flex-col items-start">
          <input
            value={value}
            type="text"
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      ) : (
        value
      )}
    </div>
  </StyledField>
);

const StyledField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
  line-height: 20px;
  .label {
    color: #6b7280;
    font-weight: 500;
    font-size: 16px;
  }
  .value {
    color: #161e2e;
    font-size: 14px;
  }
  justify-content: start;
  .label {
    width: 224px;
    text-align: left;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    .value {
      width: 100%;
    }
    .label {
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }
  }
`;
