import styled from "styled-components";
import { WeekHeader } from "./WeekHeader";
import { Day } from "./Day";
import { handleFormatDate } from "../../../helpers";

export const Dates = ({ days, onAddHoliday, data, onOpenModalInfo }) => {
  return (
    <StyledDates className="mt-6  text-sm">
      <WeekHeader />
      {Array.from(
        Array(
          days?.[0]?.getDay() === 0
            ? 6
            : days?.[0]?.getDay() === 1
            ? 0
            : days?.[0]?.getDay() - 1
        ).keys()
      )?.map((n, i) => (
        <div key={i} />
      ))}
      {days?.map((d, i) => {
        const active = data?.find(
          (dt) =>
            handleFormatDate(new Date(dt?.data_weekend)) === handleFormatDate(d)
        );
        return (
          <Day
            key={i}
            day={d?.getDate()}
            active={active}
            onClick={() => (active ? onOpenModalInfo(active) : onAddHoliday(d))}
          />
        );
      })}
    </StyledDates>
  );
};

const StyledDates = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  .fc-col-header-cell {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: black;
  }
`;
