import styled from "styled-components";

export const Confirm = ({ onClose, onConfirm }) => {
  return (
    <StyledConfirm
      className="c-modal c-time-entry"
      data-action="click->pistachio--modal#onClick keydown@window->pistachio--modal#onKeyDown"
      data-controller="pistachio--modal"
      data-pistachio--remote-modal--handler-target="modal"
      data-show="false"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="c-modal__inner">
        <div className="c-modal__content">
          <div className="animate-pulse flex space-x-4 w-64 hidden">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-6 bg-blue-200 rounded"></div>
              <div className="h-12 bg-blue-200 rounded"></div>
              <div className="flex flex-row gap-4">
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="c-modal__title">
              Are you sure you want to delete?
            </div>
            <form
              className="form w-full lg:w-[22rem]"
              action="/api/users/63/time_entries/new"
              method="post"
            >
              <input
                id="time_entry_date"
                autocomplete="off"
                type="hidden"
                value="2024-04-19"
                name="time_entry[date]"
              />
              <div className="form-actions justify-between">
                <div className="flex">
                  <input
                    type="button"
                    id="new_time_entry"
                    name="commit"
                    value="Yes"
                    className="c-button c-button--positive"
                    data-time-sheet--form-target="submitButton"
                    onClick={onConfirm}
                  />
                  <button
                    type="button"
                    className="c-button c-button--secondary modal-close"
                    data-action="#close"
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StyledConfirm>
  );
};

const StyledConfirm = styled.div`
  .c-modal__title {
    margin-bottom: 0;
  }
  .form-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0 !important;
    .flex {
      width: 100%;
    }
    .c-button {
      width: 100%;
    }
  }
`;
