import { useState } from "react";
import { handleFormatDate } from "../../helpers";

export const TimerModal = ({ onClose, activeDay }) => {
  const [data, setData] = useState({});

  const handleChangeField = (field, value) =>
    setData({ ...data, [field]: value });

  return (
    <div
      className="c-modal c-time-entry"
      data-action="click->pistachio--modal#onClick keydown@window->pistachio--modal#onKeyDown"
      data-controller="pistachio--modal"
      data-pistachio--remote-modal--handler-target="modal"
      data-show="false"
      style={{ display: "flex" }}
    >
      <div className="c-modal__inner">
        <div className="c-modal__content">
          <div className="animate-pulse flex space-x-4 w-64 hidden">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-6 bg-blue-200 rounded"></div>
              <div className="h-12 bg-blue-200 rounded"></div>
              <div className="flex flex-row gap-4">
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="c-modal__title">
              Add Entry{" "}
              <div className="c-modal__title__sub">
                {handleFormatDate(activeDay)}
              </div>
            </div>
            <form
              className="form w-full lg:w-[22rem]"
              action="/api/users/63/time_entries/new"
              method="post"
            >
              <input
                id="time_entry_date"
                autocomplete="off"
                type="hidden"
                value="2024-04-19"
                name="time_entry[date]"
              />
              <div className="space-y-3">
                <div className="w-full flex items-center">
                  <div
                    className="c-time-slider-field"
                    data-controller="time-sheet--time-slider-field"
                    data-hint="Keep pressed &amp; drag"
                    data-ref-max='input[name="time_entry[end_time]"]'
                  >
                    <div className="flex flex-col items-start">
                      <div className="relative flex rounded-md w-full">
                        <input
                          value={data?.timeFrom}
                          onChange={(e) =>
                            handleChangeField("timeFrom", e.target.value)
                          }
                          placeholder="00:00"
                          type="text"
                          autocomplete="true"
                          className="text-xl font-semibold w-28 sm:w-auto sm:min-w-20 sm:max-w-28 sm:pr-0 sm:py-1.5"
                          data-time-sheet--form-target="startTime"
                          data-action="blur->time-sheet--form#onFormatTime keyup->time-sheet--form#update change->time-sheet--form#update"
                          data-time-sheet--time-slider-field-target="input"
                          id="time_entry_start_time_70cd014e"
                          name="time_entry[start_time]"
                        />
                        <div className="inline-flex items-center shadow-sm text-sm text-gray-500 leading-5 absolute inset-y-0 right-0 pr-3">
                          <span>
                            <div
                              className="absolute right-0 top-0 bottom-0 w-10 flex items-center justify-end px-1 text-blue-700"
                              data-action="mousedown->time-sheet--time-slider-field#onMouseDown mousemove@document->time-sheet--time-slider-field#onMouseMove mouseup@document->time-sheet--time-slider-field#onMouseUp"
                            >
                              <div className="flex items-center">
                                <svg
                                  data-time-sheet--time-slider-field-target="icon"
                                  width="29"
                                  viewBox="0 0 535 318"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <g
                                      transform="translate(3.000000, 88.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M81,9.63591433 L81,133.364299 C81,141.931679 70.6539341,146.2221 64.6032408,140.164158 L2.8130753,78.2999654 C-0.937691767,74.5447048 -0.937691767,68.4555088 2.8130753,64.7002482 L64.6032408,2.83605571 C70.6539341,-3.22236732 81,1.06853483 81,9.63591433 Z"></path>
                                    </g>
                                    <g
                                      transform="translate(451.000000, 88.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M0,133.364086 L0,9.63570078 C0,1.06832128 10.3460659,-3.22210005 16.3967592,2.83584216 L78.1869247,64.7000346 C81.9376918,68.4552952 81.9376918,74.5444912 78.1869247,78.2997518 L16.3967592,140.163944 C10.3460659,146.222367 0,141.931465 0,133.364086 Z"></path>
                                    </g>
                                    <g
                                      transform="translate(114.000000, 6.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M153.5,0 C68.703629,0 0,68.703629 0,153.5 C0,238.296371 68.703629,307 153.5,307 C238.296371,307 307,238.296371 307,153.5 C307,68.703629 238.296371,0 153.5,0 Z M153.5,277.290323 C85.1058468,277.290323 29.7096774,221.894153 29.7096774,153.5 C29.7096774,85.1058468 85.1058468,29.7096774 153.5,29.7096774 C221.894153,29.7096774 277.290323,85.1058468 277.290323,153.5 C277.290323,221.894153 221.894153,277.290323 153.5,277.290323 Z M191.75121,212.671774 L139.202218,174.48246 C137.283468,173.058871 136.169355,170.830645 136.169355,168.478629 L136.169355,66.8467742 C136.169355,62.7616935 139.511694,59.4193548 143.596774,59.4193548 L163.403226,59.4193548 C167.488306,59.4193548 170.830645,62.7616935 170.830645,66.8467742 L170.830645,154.552218 L212.176613,184.633266 C215.518952,187.047177 216.199798,191.689315 213.785887,195.031653 L202.149597,211.0625 C199.735685,214.342944 195.093548,215.085685 191.75121,212.671774 Z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 text-base text-gray-500 font-medium">
                    to
                  </div>
                  <div
                    className="c-time-slider-field"
                    data-controller="time-sheet--time-slider-field"
                    data-hint="Keep pressed &amp; drag"
                    data-ref-min='input[name="time_entry[start_time]"]'
                  >
                    <div className="flex flex-col items-start">
                      <div className="relative flex rounded-md w-full">
                        <input
                          type="text"
                          value={data?.timeTo}
                          onChange={(e) =>
                            handleChangeField("timeTo", e.target.value)
                          }
                          placeholder="00:00"
                          autocomplete="true"
                          className="text-xl font-semibold w-28 sm:w-auto sm:min-w-20 sm:max-w-28 sm:pr-0 sm:py-1.5"
                          data-time-sheet--form-target="endTime"
                          data-action="blur->time-sheet--form#onFormatTime keyup->time-sheet--form#update change->time-sheet--form#update"
                          data-time-sheet--time-slider-field-target="input"
                          id="time_entry_end_time_70cd014e"
                          name="time_entry[end_time]"
                        />
                        <div className="inline-flex items-center shadow-sm text-sm text-gray-500 leading-5 absolute inset-y-0 right-0 pr-3">
                          <span>
                            <div
                              className="absolute right-0 top-0 bottom-0 w-10 flex items-center justify-end px-1 text-blue-700"
                              data-action="mousedown->time-sheet--time-slider-field#onMouseDown mousemove@document->time-sheet--time-slider-field#onMouseMove mouseup@document->time-sheet--time-slider-field#onMouseUp"
                            >
                              <div className="flex items-center">
                                <svg
                                  data-time-sheet--time-slider-field-target="icon"
                                  width="29"
                                  viewBox="0 0 535 318"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <g
                                      transform="translate(3.000000, 88.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M81,9.63591433 L81,133.364299 C81,141.931679 70.6539341,146.2221 64.6032408,140.164158 L2.8130753,78.2999654 C-0.937691767,74.5447048 -0.937691767,68.4555088 2.8130753,64.7002482 L64.6032408,2.83605571 C70.6539341,-3.22236732 81,1.06853483 81,9.63591433 Z"></path>
                                    </g>
                                    <g
                                      transform="translate(451.000000, 88.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M0,133.364086 L0,9.63570078 C0,1.06832128 10.3460659,-3.22210005 16.3967592,2.83584216 L78.1869247,64.7000346 C81.9376918,68.4552952 81.9376918,74.5444912 78.1869247,78.2997518 L16.3967592,140.163944 C10.3460659,146.222367 0,141.931465 0,133.364086 Z"></path>
                                    </g>
                                    <g
                                      transform="translate(114.000000, 6.000000)"
                                      fill="currentColor"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M153.5,0 C68.703629,0 0,68.703629 0,153.5 C0,238.296371 68.703629,307 153.5,307 C238.296371,307 307,238.296371 307,153.5 C307,68.703629 238.296371,0 153.5,0 Z M153.5,277.290323 C85.1058468,277.290323 29.7096774,221.894153 29.7096774,153.5 C29.7096774,85.1058468 85.1058468,29.7096774 153.5,29.7096774 C221.894153,29.7096774 277.290323,85.1058468 277.290323,153.5 C277.290323,221.894153 221.894153,277.290323 153.5,277.290323 Z M191.75121,212.671774 L139.202218,174.48246 C137.283468,173.058871 136.169355,170.830645 136.169355,168.478629 L136.169355,66.8467742 C136.169355,62.7616935 139.511694,59.4193548 143.596774,59.4193548 L163.403226,59.4193548 C167.488306,59.4193548 170.830645,62.7616935 170.830645,66.8467742 L170.830645,154.552218 L212.176613,184.633266 C215.518952,187.047177 216.199798,191.689315 213.785887,195.031653 L202.149597,211.0625 C199.735685,214.342944 195.093548,215.085685 191.75121,212.671774 Z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ml-auto pl-2 leading-none text-xl font-semibold text-gray-700"
                    data-time-sheet--form-target="durationDisplay"
                  ></div>
                </div>
                <div className="flex">
                  <div className="w-full grid grid-cols-1 space-y-2">
                    <select
                      className="js-example-basic-single select2-hidden-accessible"
                      name="time_entry[project_id]"
                      data-select2-id="select2-data-1-kj8w"
                      tabindex="-1"
                      aria-hidden="true"
                      value={data?.projectId}
                      onChange={(e) =>
                        handleChangeField("projectId", e.target.value)
                      }
                    >
                      <option value="1" data-select2-id="select2-data-3-kymp">
                        Intern
                      </option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--default"
                      dir="ltr"
                      data-select2-id="select2-data-2-chzk"
                      style={{ width: 230 }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-disabled="false"
                          aria-labelledby="select2-time_entryproject_id-yw-container"
                          aria-controls="select2-time_entryproject_id-yw-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-time_entryproject_id-yw-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Intern"
                          >
                            Intern
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span
                        className="dropdown-wrapper"
                        aria-hidden="true"
                      ></span>
                    </span>
                    <select
                      className="js-example-basic-single select2-hidden-accessible"
                      name="time_entry[task_id]"
                      data-select2-id="select2-data-4-lbwz"
                      tabindex="-1"
                      aria-hidden="true"
                      value={data?.taskId}
                      onChange={(e) =>
                        handleChangeField("taskId", e.target.value)
                      }
                    >
                      <option value="1" data-select2-id="select2-data-6-hdr5">
                        Administration
                      </option>
                      <option value="2">Allgemein</option>
                      <option value="3">Meeting</option>
                      <option value="4">Umsetzung</option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--default"
                      dir="ltr"
                      data-select2-id="select2-data-5-hflh"
                      style={{ width: 230 }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-disabled="false"
                          aria-labelledby="select2-time_entrytask_id-ji-container"
                          aria-controls="select2-time_entrytask_id-ji-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-time_entrytask_id-ji-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Administration"
                          >
                            Administration
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span
                        className="dropdown-wrapper"
                        aria-hidden="true"
                      ></span>
                    </span>
                  </div>
                  <div className="w-8 pl-4 flex items-center">
                    <div
                      className="c-dropdown c-dropdown--right"
                      data-action="click@document->pistachio--dropdown#onDocumentClick"
                      data-controller="pistachio--dropdown"
                    >
                      <div className="c-dropdown__wrapper">
                        <div
                          className="c-dropdown__toggle"
                          data-action="click->pistachio--dropdown#onToggleClick"
                          data-pistachio--dropdown-target="toggle"
                        >
                          <a
                            tabindex="-1"
                            title="Recently used…"
                            href="javascript:;"
                          >
                            <i className="icon3000 icon-clock-rotate-left">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="currentColor"
                              >
                                <path d="M48 106.7V56c0-13.3-10.7-24-24-24S0 42.7 0 56V168c0 13.3 10.7 24 24 24H136c13.3 0 24-10.7 24-24s-10.7-24-24-24H80.7c37-57.8 101.7-96 175.3-96c114.9 0 208 93.1 208 208s-93.1 208-208 208c-42.5 0-81.9-12.7-114.7-34.5c-11-7.3-25.9-4.3-33.3 6.7s-4.3 25.9 6.7 33.3C155.2 496.4 203.8 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C170.3 0 94.4 42.1 48 106.7zM256 128c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"></path>
                              </svg>
                            </i>
                          </a>
                        </div>
                        <div
                          className="c-dropdown__window"
                          data-pistachio--dropdown-target="window"
                        >
                          <div className="c-dropdown__content">
                            <div className="c-dropdown__links sm:max-w-screen-80 lg:max-w-sm">
                              <a
                                href="javascript:;"
                                className="max-w-full flex py-1 text-xs whitespace-nowrap"
                                data-action="time-sheet--form#onSelectProjectAndTask pistachio--dropdown#close"
                                data-project-id="1"
                                data-task-id="1"
                              >
                                <span className="max-w-full inline-block truncate">
                                  Intern
                                </span>
                                <span className="inline-block px-1">- </span>
                                <span className="max-w-full inline-block truncate">
                                  Administration
                                </span>
                              </a>
                              <a
                                href="javascript:;"
                                className="max-w-full flex py-1 text-xs whitespace-nowrap"
                                data-action="time-sheet--form#onSelectProjectAndTask pistachio--dropdown#close"
                                data-project-id="1"
                                data-task-id="2"
                              >
                                <span className="max-w-full inline-block truncate">
                                  Intern
                                </span>
                                <span className="inline-block px-1">- </span>
                                <span className="max-w-full inline-block truncate">
                                  Allgemein
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <textarea
                    placeholder="Note (optional)"
                    id="time_entry_note_70cd014e"
                    className=""
                    name="time_entry[note]"
                    value={data?.note}
                    onChange={(e) => handleChangeField("note", e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="form-actions justify-between">
                <div className="flex">
                  <input
                    type="button"
                    id="new_time_entry"
                    name="commit"
                    value="Start Timer"
                    className="c-button c-button--positive"
                    data-time-sheet--form-target="submitButton"
                    onClick={onClose}
                  />
                  <button
                    type="button"
                    className="c-button c-button--secondary modal-close"
                    data-action="#close"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
