import styled from "styled-components";
import { Card } from "../../components/Tracking/Timers/Card";
import React, { useEffect, useState } from "react";
import { getUserTimersById } from "../../api/methods";
import { Empty } from "../../components/Tracking/Timers/Empty";
import loader from "../../assets/loading.svg";

export const InfoList = ({ id }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserTimersById(id).then((resp) => {
      setData(resp?.data?.date ?? {});
      setLoading(false);
    });
  }, [id]);

  return (
    <StyledInfoList>
      {loading ? (
        <img src={loader} alt="" className="loader" />
      ) : Object.entries(data)?.length > 0 ? (
        Object.entries(data)?.map((t, i) => (
          <React.Fragment key={i}>
            <div className="listDate">{t[0]}</div>
            {t[1]?.map(({ date_start, date_end, id }) => (
              <Card
                key={id}
                readOnly
                date={new Date(date_start)}
                endDate={new Date(date_end)}
              />
            ))}
          </React.Fragment>
        ))
      ) : (
        <Empty />
      )}
    </StyledInfoList>
  );
};

const StyledInfoList = styled.div`
  .listDate {
    margin: 20px 0 10px;
    font-weight: bold;
    max-height: 300px;
    overflow: auto;
  }
  .loader {
    margin: 30px auto;
    height: 40px;
  }
`;
