import styled from "styled-components";
import { Row } from "./Row";
import { useState } from "react";
import { HolidayModal } from "../Dashboard/Calendar/HolidayModal";

export const Table = ({ days, users, onPrint }) => {
  const [openDate, setOpenDate] = useState(null);

  return (
    <>
      {openDate && (
        <HolidayModal onClose={() => setOpenDate(null)} data={openDate} />
      )}
      <StyledTable>
        <div className="top"></div>
        <div
          className="days"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${days?.length}, 1fr)`,
          }}
        >
          {days?.map((d, key) => {
            var daysNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            return (
              <div key={key}>
                <div className="font-semibold">{d?.getDate()}</div>
                <div className="text-gray-500">{daysNames[d?.getDay()]}</div>
              </div>
            );
          })}
        </div>
        {users?.map((user) => (
          <Row
            key={user?.id}
            user={user}
            days={days}
            onOpenHoliday={(val) => setOpenDate(val)}
            onPrint={onPrint}
          />
        ))}
      </StyledTable>
    </>
  );
};

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  border: 1px solid rgb(210 214 220 / 1);
  border-radius: 0.25rem;
  background: #fff;
  max-width: calc(100svw - 10px);
  overflow: auto;
  .calendar-row {
    display: flex;
    align-items: center;
    border: 1px solid rgb(210 214 220 / 1);
    min-height: 40px;
    padding: 8px;
    border-bottom: 0;
    flex-wrap: wrap;
    word-break: break-all;
    &.calendar-row--first {
      border-left: none !important;
    }
    .small-avatar {
      width: 24px;
      height: 24px;
      font-size: 10px;
    }
    &.calendar-row-active {
      background: rgba(113, 192, 117, 0.2);
      cursor: pointer;
    }
  }
  .days {
    text-align: center;
    div {
      padding: 1px;
    }
  }
`;
