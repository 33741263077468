import styled from "styled-components";
import { NotAuthedHeader } from "../../components/NotAuthedHeader";
import { useEffect, useState } from "react";
import { LoginForm } from "./LoginForm";
import { ForgotPassword } from "./ForgotPassword";
import { useNavigate } from "react-router-dom";

export const Auth = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <StyledAuth className="login-content">
      <div className="bglogin">
        <NotAuthedHeader />
        {forgotPassword ? (
          <ForgotPassword onClose={() => setForgotPassword(false)} />
        ) : (
          <LoginForm onForgotPassword={() => setForgotPassword(true)} />
        )}
      </div>
    </StyledAuth>
  );
};

const StyledAuth = styled.div`
  min-height: 100vh;
  label {
    text-align: left !important;
  }
`;
