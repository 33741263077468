import styled from "styled-components";

export const SmallAvatar = ({ name, lastName, avatar, className }) => (
  <StyledSmallAvatar avatar={avatar} className={className}>
    {avatar?.length > 0 ? null : (
      <>
        {" "}
        {name?.toUpperCase()?.[0]}
        {lastName?.toUpperCase()?.[0]}
      </>
    )}
  </StyledSmallAvatar>
);

const StyledSmallAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: url(${({ avatar }) => avatar}) center/cover no-repeat, #9fa6b2;
  font-family: "Arial-BoldMT", "Arial", sans-serif;
  font-weight: 700;
  color: #ffffffc9;
  font-size: 14px;
`;
