import styled from "styled-components";
import { handleFormatDate } from "../../helpers";
import leftArrow from "../../assets/left-arrow.svg";
import rightArrow from "../../assets/right-arrow.svg";
import arrow from "../../assets/calendar-arrow.svg";
import flatpickr from "flatpickr";
import { useEffect, useRef } from "react";
import monthSelect from "flatpickr/dist/plugins/monthSelect";

export const Header = ({ date, onChangeDate }) => {
  const calendar = useRef();

  const handlePrevMonth = () =>
    onChangeDate(new Date(date.setMonth(date.getMonth() - 1)));
  const handleNextMonth = () =>
    onChangeDate(new Date(date.setMonth(date.getMonth() + 1)));

  useEffect(() => {
    calendar.current = flatpickr(".flatpickrInput", {
      onChange: (val) => onChangeDate(val?.[0], true),

      plugins: [
        new monthSelect({
          shorthand: true, //defaults to false
          theme: "light",
        }),
      ],
    });
  }, []);

  useEffect(() => {
    calendar.current.setDate && calendar.current.setDate(date);
  }, [date]);

  return (
    <StyledHeader>
      <div className="flex items-center mb-4" id="staff_calendar">
        <div className="w-full flex items-center">
          <div className="c-button-group">
            <a
              className="c-button c-button--secondary c-button--icon-only change-month-btn"
              onClick={handlePrevMonth}
            >
              <img src={leftArrow} alt="" sizes="" />
            </a>
            <a
              className="c-button c-button--secondary c-button--icon-only change-month-btn"
              onClick={handleNextMonth}
            >
              <img src={rightArrow} alt="" />
            </a>
          </div>
          <div className="ml-2 text-xl flex items-center gap-1">
            <div>
              <strong>This Month:</strong>
              <span className="date-box">
                {handleFormatDate(date, false, true)}
              </span>
            </div>
            <div>
              <div className="flatpickr_year_month flatpickrInput">
                <input type="text" />
                <a className="input-button" title="toggle" data-toggle="">
                  <img src={arrow} alt="" className="arrow-down-calendar" />
                </a>
              </div>
            </div>
          </div>
          <div className="ml-auto flex items-center">
            <div className="flex space-x-1"></div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  input {
    width: 0px;
    height: 0px;
    padding: 0px;
    margin: 0px;
    border: 0;
    
  }
  .flatpickr_year_month {
    input {
      opacity: 0;
      height: 0;
      padding: 0;
    }
  }
  .arrow-down-calendar {
    height: 20px;
  }
  .change-month-btn {
    img {
      height: 10px;
      width: 10px;
    }
  }
  .flatpickr-monthSelect-months {
  }
  .flatpickr-monthSelect-month {
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin: 0.5px;
    justify-content: center;
    padding: 10px;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    width: 32%;
  }
  .date-box {
    margin-left: 10px;
  }
`;
