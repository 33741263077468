import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NotAuthedHeader = () => (
  <StyledNotAuthedHeader className="container">
    <NavLink to={"https://persanesth.com"} activeClassName="active">
      {" "}
      Home
    </NavLink>
    <NavLink to={"/auth"}> Login</NavLink>
  </StyledNotAuthedHeader>
);

const StyledNotAuthedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  gap: 5px;
  position: relative;
  z-index: 2;
  a {
    padding: 15px 20px;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    &:hover,
    &.active {
      background-color: hsla(0, 0%, 100%, 0.1);
    }
  }
`;
