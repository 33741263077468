import styled from "styled-components";
import { HolidayTag } from "../../../HolidayTag";

export const Day = ({ title, duration, onClick, active, total, holiday }) => (
  <a
    class={`p-2 ${active && "bg-gray-200"} ${holiday && "holiday"}`}
    onClick={onClick}
  >
    <div className="font-medium text-gray-800">{title}</div>
    <div className="text-gray-800">
      <span style={{ color: " gray" }} className="duration">
        {holiday ? <HolidayTag /> : total}
      </span>
    </div>
  </a>
);
