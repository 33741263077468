import styled from "styled-components";

export const Total = ({ total, totalPause }) => (
  <StyledTotal data-time-sheet-target="dailySummary">
    <div className="w-full flex justify-end text-lg p-4 lg:pr-24 border border-transparent sm:justify-between">
      <div className="text-right text-gray-500 font-medium">Total</div>
      <div className="text-right font-semibold">
        <span className="duration" data-hhmm-duration="9:10">
          {total}
        </span>
        <span className="duration totalPause" data-hhmm-duration="9:10">
          ({totalPause})
        </span>
      </div>
    </div>
  </StyledTotal>
);

const StyledTotal = styled.div`
  .duration {
    margin-left: 5px;
  }
  .totalPause {
    color: rgb(107 114 128 /1);
  }
`;
