import { useState } from "react";
import { resetPassword } from "../../api/methods";
import cogoToast from "cogo-toast";

export const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (email?.length > 0) {
      resetPassword(email)
        .then((resp) => {
          cogoToast.success("Success");
          onClose();
        })
        .catch(() => {
          cogoToast.error("Error");
        });
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <p className="login-title">Passwort-Wiederherstellung</p>
      <div className="inp-field">
        <label for="em-addr">E-Mail-Adresse</label>
        <input
          id="em-addr"
          type="email"
          placeholder="E-Mail-Adresse"
          name="email"
          required=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="text-center">
        <input
          type="submit"
          value="Senden"
          disabled={email?.length === 0}
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};
